import React, { Component } from 'react';
import * as Api from '../utils/Api';
import logo from '../assets/images/finbe.png';
import spinnerGif from '../assets/images/spinner.gif';

class Authorization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waiting: false,
      password: '',
      confirmPassword: '',
      formError: '',
      noAuth: false,
      switch: false,
      canSubmitForm: false,
      formSubmitted: false,
      alert: { type: null, text: '' }
    };

    this.handleInput = this.handleInput.bind(this);
    this.notAuthorize = this.notAuthorize.bind(this);
    this.handleInputConfirm = this.handleInputConfirm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInput(e) {
    const { value } = e.target;

    this.setState(
      {
        password: value
      },
      () => this.validateInput(value)
    );
  }

  handleInputConfirm(e) {
    const { value } = e.target;

    this.setState(
      {
        confirmPassword: value
      },
      () => this.matchInputs(value)
    );
  }

  validateInput(value) {
    let formError = '';

    if (value.length === 0) {
      formError = 'Ingrese su contraseña';
    }

    if (value.length > 12) {
      formError = 'Maximo 12 caracteres';
    }

    this.setState({
      formError
    });
  }

  matchInputs(value) {
    if (this.state.password === value) {
      this.setState({
        canSubmitForm: true
      });
    } else {
      this.setState({
        canSubmitForm: false
      });
    }
  }

  async notAuthorize(e) {
    e.preventDefault();

    this.setState({
      waiting: true
    });

    const { userUuid } = this.props;
    const { password } = this.state;
    const user = await Api.authorize(userUuid, password, false);

    let alert = { ...this.state.alert };
    let formSubmitted = false;

    if (user) {
      alert.type = 'success';
      alert.text = 'Se realizó la autorización exitosamente';
      formSubmitted = true;
    } else {
      alert.type = 'error';
      alert.text = 'El nip no es correcto';
    }

    this.setState({
      password: '',
      confirmPassword: '',
      canSubmitForm: false,
      formSubmitted: formSubmitted,
      alert,
      waiting: false,
      noAuth: formSubmitted,
      switch: true
    });
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.setState({
      waiting: true
    });

    const { userUuid } = this.props;
    const { password } = this.state;
    const user = await Api.authorize(userUuid, password, true);
    let alert = { ...this.state.alert };
    let formSubmitted = false;
    if (user) {
      alert.type = 'success';
      alert.text = 'Se realizó la autorización exitosamente';
      formSubmitted = true;
    } else {
      alert.type = 'error';
      alert.text = 'El nip no es correcto';
    }
    this.setState({
      password: '',
      confirmPassword: '',
      switch: true,
      canSubmitForm: false,
      formSubmitted: formSubmitted,
      alert,
      waiting: false
    });
  }

  render() {
    let notification = null;
    let frame = null;
    let spinner = null;

    if (this.props.errorMsg) {
      notification = (
        <div className={'form-alert form-alert--error'}>
          {this.props.errorMsg}
        </div>
      );
    }

    if (this.state.alert.type) {
      notification = (
        <div className={`form-alert form-alert--${this.state.alert.type}`}>
          {this.state.alert.text}
        </div>
      );
    }

    if (this.state.formSubmitted && !this.state.noAuth) {
      frame = (
        <div className="auth-message">
          <p className="auth-message__p1">Su autorización ha sido recibida.</p>
          <p className="auth-message__p2">
            Su Ejecutivo se comunicará con usted próximamente.
          </p>
        </div>
      );
    } else if (this.state.noAuth && this.state.formSubmitted) {
      frame = (
        <div className="auth-message">
          <p className="auth-message__p1">Ha rechazado su autorización.</p>
          <p className="auth-message__p2">Muchas gracias.</p>
        </div>
      );
    }

    if (this.state.waiting) {
      spinner = (
        <div
          className=""
          style={{
            textAlign: 'center',
            background: '#F68C2C',
            borderRadius: '5px'
          }}
        >
          <img
            className=""
            src={spinnerGif}
            alt="cargando"
            style={{
              margin: '5px 0',
              height: '35px',
              width: 'auto',
              textAlign: 'center'
            }}
          />
        </div>
      );
    } else {
      spinner = (
        <div className="form-button">
          <input
            className="btn-red"
            onClick={this.notAuthorize}
            type="button"
            value="No Autorizo"
          />
          <input
            className="btn-green"
            type="submit"
            value="Si Autorizo"
            disabled={!this.state.canSubmitForm}
          />
        </div>
      );
    }

    return (
      <div className="form-container">
        <div className="form-container__image">
          <img src={logo} alt="finbe-logo"/>
        </div>
        {this.state.switch ? (
          frame
        ) : (
          <form onSubmit={this.handleSubmit}>
            {notification}
            <p>
              Por este conducto autorizo expresamente a Financiera Bepensa, para
              que por conducto de sus funcionarios facultados lleve a cabo
              Investigaciones, sobre mi comportamiento Crediticio en las
              Sociedades de Información Crediticia que estime conveniente.
              <br/>
              <br/>
              Así mismo, declaro que conozco la naturaleza y alcance de la
              información que se solicitará, del uso que Financiera Bepensa hará
              de tal información y de que ésta podrá realizar consultas
              periódicas de mi historial crediticio, consintiendo que esta
              autorización se encuentre vigente por un período de 3 años
              contados a partir de la fecha de su expedición y en todo caso
              durante el tiempo que mantengamos relación jurídica.
            </p>
            <div className="form-field">
              <label>Para autorizar, ingresar nuevamente su NIP: </label>
              <input
                type="password"
                name="password"
                placeholder="Ingrese su NIP"
                value={this.state.password}
                onChange={this.handleInput}
              />
              {this.state.formError && (
                <p className="form-error">{this.state.formError}</p>
              )}
            </div>
            <div className="form-field">
              <input
                type="password"
                name="password"
                placeholder="Confirmar tu NIP"
                value={this.state.confirmPassword}
                onChange={this.handleInputConfirm}
              />
            </div>

            {spinner}
          </form>
        )}
      </div>
    );
  }
}

export default Authorization;
