import React, { Component } from "react";
import "./App.css";
import qs from "qs";
import * as Api from "./utils/Api";

import Authorization from "./Authorization/Authorization";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userUuid: null,
      errorMsg: null,
      method: ""
    };

    this.fetchUserUuidFromURL = this.fetchUserUuidFromURL.bind(this);
    this.fetchUserByUuid = this.fetchUserByUuid.bind(this);
    this.hasUuidExpired = this.hasUuidExpired.bind(this);
  }

  componentDidMount() {
    this.fetchUserUuidFromURL();
  }

  fetchUserUuidFromURL() {
    const rawParams = window.location.search.substr(1);
    const { uuid = null, method = "" } = qs.parse(rawParams);

    this.setState({
      method
    });

    if (uuid) {
      this.fetchUserByUuid(uuid, method);
      return;
    }

    this.setState({
      errorMsg: "Ocurrió un error, intente de nuevo"
    });
  }

  async fetchUserByUuid(uuid, method) {
    const user = await Api.fetchUserByUuid(uuid, method);
    let userUuid = null;
    let errorMsg = null;
    if (user) {
      if (!this.hasUuidExpired(user.nip_expiration)) {
        userUuid = uuid;
      } else {
        errorMsg = "El UUID proporcionado ya ha expirado";
      }
    } else {
      errorMsg = "El usuario no fue encontrado";
    }

    console.log(userUuid);

    this.setState({
      userUuid,
      errorMsg
    });
  }

  hasUuidExpired(expirationDate) {
    const currentDate = new Date().getTime();
    expirationDate = new Date(expirationDate).getTime();
    return currentDate > expirationDate;
  }

  render() {
    return (
      <div className="container">
        <Authorization
          userUuid={this.state.userUuid}
          errorMsg={this.state.errorMsg}
        />
        <p className="terms-conditions">
          <a href="https://www.finbe.com.mx/SitePages/Aviso%20Legal.aspx">
            Terminos y condiciones
          </a>{" "}
          ·
          <a href="https://www.finbe.com.mx/SitePages/Aviso%20de%20Privacidad.aspx">
            {" "}
            Aviso de privacidad
          </a>
        </p>
      </div>
    );
  }
}

export default App;
