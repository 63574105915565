import axios from "axios";
import { bugsnagClient } from "../bugsnag";
import qs from "qs";

const fetchUserByUuid = async (uuid, method) => {
  try {
    const resp = await axios.get(
      `/credit-bureau?uuid=${uuid}&picker=${method}`
    );
    const {
      data: { status, title }
    } = resp;

    if (status !== 200) {
      bugsnagClient.notify(new Error(title));
      throw new Error(title);
    }

    return resp.data.data.user;
  } catch (error) {
    return null;
  }
};

const authorize = async (uuid, nip, is_authorized) => {
  const body = {
    uuid,
    nip,
    is_authorized,
  };

  const rawParams = window.location.search.substr(1);
  const { method = "" } = qs.parse(rawParams);

  try {
    const resp = await axios.put(`credit-bureau?picker=${method}`, body);

    const {
      data: { status, title }
    } = resp;

    if (status !== 200) {
      bugsnagClient.notify(new Error(title));
      throw new Error(title);
    }

    console.log(resp.data);

    return resp.data.data.user;
  } catch (error) {
    bugsnagClient.notify(new Error(error));
    console.log(error.response.data);
    return null;
  }
};

export { fetchUserByUuid, authorize };
